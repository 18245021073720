import { Paper, Flex, Title, Divider, Text } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { colors } from "../theme/colors";
import { includesDate } from "../utils/helpers";
import { useContext } from "react";
import { NavContext } from "@ionic/react";
import classes from "./AppointmentDateSelect.module.css";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { useIsMobile } from "../hooks/useIsMobile";
import { useLocation } from "react-router";

dayjs.extend(utc);
dayjs.extend(timezone);

interface AppointmentDateSelectProps {
  availabilityDates: dayjs.Dayjs[];
  consultId: string;
  defaultTz: string;
  title: string;
  description: string;
}

const AppointmentDateSelect = ({
  availabilityDates,
  defaultTz,
  title,
  description,
}: AppointmentDateSelectProps) => {
  const navContext = useContext(NavContext);
  const isMobile = useIsMobile();
  const location = useLocation();
  const urlRoot = location.pathname.split("/")[1];

  const excludeDate = (date: Date) => {
    return !includesDate(availabilityDates, dayjs(date).tz(defaultTz).hour(0));
  };

  const handleDateChange = (date: Date | null) => {
    navContext.navigate(`/${urlRoot}/1/${date?.getTime()}`, "forward");
  };

  return (
    <Paper radius="md" my={"lg"}>
      <Flex justify={isMobile ? "flex-start" : "center"}>
        <Title fz="28px" fw={600} c={colors.black} lh="36px">
          {title}
        </Title>
      </Flex>
      <Flex justify={isMobile ? "flex-start" : "center"} mt="lg">
        <Text fz="16px" c={colors.nearBlack} lh="24px">
          {description}
        </Text>
      </Flex>
      <Divider my="lg" />
      <DatePicker
        size="lg"
        classNames={classes}
        styles={{
          levelsGroup: { justifyContent: "center" },
        }}
        excludeDate={(date) => excludeDate(date)}
        weekendDays={[]}
        onChange={(date) => handleDateChange(date)}
      ></DatePicker>
    </Paper>
  );
};

export default AppointmentDateSelect;
