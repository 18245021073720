import axios from "axios";
import { get } from "lodash";
import { ConfigType } from "../typings";

export async function API_Root<T, Y>(config: ConfigType<Y>) {
  try {
    return await axios<T>(config);
  } catch (error) {
    console.log(
      `Api error (${get(error, "response.status", "unknown")}) from ${config.url}: `
    );
    throw error;
  }
}
