import { useQuery } from "@tanstack/react-query";
import { useAuth } from "../../context/AuthContext";
import { getAllMealPlans } from "../../services/mealPlans";

export const useGetMealPlan = (readyToLoad?: boolean) => {
  const { legacyApiUserToken } = useAuth();

  return useQuery({
    queryKey: ["legacy api", "get meal plan"],
    queryFn: () => getAllMealPlans(legacyApiUserToken),
    enabled: !!readyToLoad && !!legacyApiUserToken,
  });
};
