import { ToastType } from "../context/ToastContext";

export const getToastIcon = (type: ToastType) => {
  switch (type) {
    // TODO: add other icons for each toast type
    case "success":
      return "/green-check.svg";
    case "error":
      return "/red-alert.svg";
    default:
      return "/green-check.svg";
  }
};
