import { Flex, Loader } from "@mantine/core";
import { colors } from "../theme/colors";
import { IonContent, IonPage } from "@ionic/react";

export const FHLoader = () => {
  return (
    <IonPage>
      <IonContent>
        <Flex
          display="flex"
          w={"100%"}
          h={"100%"}
          align={"center"}
          justify={"center"}
        >
          <Loader size="lg" mt="xl" color={colors.blurple}></Loader>
        </Flex>
      </IonContent>
    </IonPage>
  );
};
