import { useCallback, useEffect, useState } from "react";
import { filter, findIndex } from "lodash";
import dayjs from "dayjs";

import { MealLabels } from "../typings/enums";
import FuturHealthLegacyAPI from "../utils/FuturHealthLegacyAPI";
import { ChoosingRecipe, SwapRecipeType } from "../typings";
import { useGetAllCheckin } from "./react-query/useGetAllCheckins";
import { useLocalForage } from "./useLocalForage";
import { calculateDaysSinceCheckin } from "../utils/calculateDaysSinceCheckin";

const MAX_CHOOSABLE_COUNT_PER_MEAL = 3;

const DEFAULT_CHECKIN_RESULT = {
  mood: "",
  hunger: "",
  sleep: "",
  weight: "0.00",
  hips: "0.00",
  waist: "0.00",
  prior_week_success: false,
  created_at: dayjs().format(),
};

export const useMealPlan = (token: string | null) => {
  const [isLoadingSwappableRecipes, setIsLoadingSwappableRecipes] =
    useState(false);
  const [legacyUserInfo, setLegacyUserInfo] = useState<IUser | null>(null);
  const [swappableRecipes, setSwappableRecipes] = useState<SwapRecipeType>({
    [MealLabels.breakfast]: [],
    [MealLabels.lunch]: [],
    [MealLabels.dinner]: [],
  });
  const [choosingResult, setChoosingResult] = useState<ChoosingRecipe[]>([]);
  const { data: allCheckinsData } = useGetAllCheckin();
  const [mealPlanPersistentData, setChosenQuizMealOptions] = useLocalForage(
    "quiz_data_meal_planner"
  );

  const loginWithToken = useCallback(async (token: string) => {
    if (!token) {
      throw new Error("Token does not exist.");
    }

    try {
      const { data } = await FuturHealthLegacyAPI.checkAuth(token);
      setLegacyUserInfo(data.user);
    } catch (error) {
      console.error("error legacy user token: ", error);
    }
  }, []);

  const skipCheckin = useCallback(
    async (token: string, legacyUserInfo: IUser) => {
      const { daysSinceCheckin } = calculateDaysSinceCheckin(legacyUserInfo);

      if (daysSinceCheckin > 6) {
        let results: Partial<ICheckin> = DEFAULT_CHECKIN_RESULT;
        const allCheckins = allCheckinsData?.data;
        try {
          if (allCheckins?.length) {
            const lastCheckin = allCheckins[0];
            const lastCheckinParse = {
              mood: lastCheckin.mood ?? "",
              hunger: lastCheckin.hunger ?? "",
              sleep: lastCheckin.sleep ?? "",
              weight: lastCheckin.weight ?? "0",
              hips: lastCheckin.hips ?? "0",
              waist: lastCheckin.waist ?? "0",
              created_at: lastCheckin.created_at ?? dayjs().format(),
              prior_week_success: false,
            };
            results = { ...lastCheckinParse, prior_week_success: false };
          }
          await FuturHealthLegacyAPI.createCheckin(token, results);
          await loginWithToken(token);
        } catch (error) {
          console.error("error auto check-in: ", error);
        }
      }
    },
    [allCheckinsData?.data, loginWithToken]
  );

  const getSwappableRecipes = useCallback(
    async (token: string, weekNumber: number) => {
      setIsLoadingSwappableRecipes(true);
      const swappableRecipesBuffer: SwapRecipeType = {
        [MealLabels.breakfast]: [],
        [MealLabels.lunch]: [],
        [MealLabels.dinner]: [],
      };

      try {
        // get swappable recipes by label
        const { data: daySwappableRecipesByLabel } =
          await FuturHealthLegacyAPI.getSwappableRecipesByLabel(
            token,
            weekNumber
          );

        swappableRecipesBuffer[MealLabels.breakfast].push(
          ...daySwappableRecipesByLabel[MealLabels.breakfast]
        );

        swappableRecipesBuffer[MealLabels.lunch].push(
          ...daySwappableRecipesByLabel[MealLabels.lunch]
        );

        swappableRecipesBuffer[MealLabels.dinner].push(
          ...daySwappableRecipesByLabel[MealLabels.dinner]
        );

        setSwappableRecipes(swappableRecipesBuffer);
      } catch (error) {
        console.error("error fetching swappable recipes: ", error);
      }
      setSwappableRecipes(swappableRecipesBuffer);
      setIsLoadingSwappableRecipes(false);
    },
    []
  );

  const updateChoosingRecipe = useCallback(
    (choosingRecipes: ChoosingRecipe[], isSelect: boolean) => {
      let choosingRecipeResult = choosingResult.slice();

      if (isSelect) {
        const label = choosingRecipes[0].label;
        const count = filter(choosingResult, { label }).length;
        if (count >= MAX_CHOOSABLE_COUNT_PER_MEAL) {
          // Remove the first meal
          const index = findIndex(choosingRecipeResult, { label });
          choosingRecipeResult.splice(index, 1);
        }
        choosingRecipeResult = choosingRecipeResult.concat(choosingRecipes);
      } else {
        choosingRecipes.forEach(({ recipe, label }) => {
          const index = findIndex(choosingRecipeResult, { recipe, label });
          if (index !== -1) {
            choosingRecipeResult.splice(index, 1);
          }
        });
      }

      setChosenQuizMealOptions(JSON.stringify(choosingRecipeResult));
      setChoosingResult(choosingRecipeResult);
    },
    [choosingResult, setChosenQuizMealOptions]
  );

  const removeChoosingRecipeByLabel = useCallback(
    (mealLabel: MealLabels) => {
      if (!mealLabel) return;

      const choosingRecipeResult = choosingResult.filter(
        ({ label }) => label !== mealLabel
      );
      setChoosingResult(choosingRecipeResult);
    },
    [choosingResult]
  );

  const clearChoosingRecipe = useCallback(() => {
    setChoosingResult([]);
  }, []);

  const sendChoosingResult = useCallback(
    async (weekNumber: number) => {
      if (choosingResult.length && token) {
        const formattedResult = choosingResult.map((_el) => ({
          recipe: _el.recipe,
          label: _el.label,
        }));
        try {
          await FuturHealthLegacyAPI.swapToRecipes(
            token,
            formattedResult,
            weekNumber
          );
        } catch (error) {
          console.error("error choosing recipe post:", error);
        } finally {
          clearChoosingRecipe();
        }
      }
    },
    [choosingResult, token, clearChoosingRecipe]
  );

  useEffect(() => {
    if (token) {
      loginWithToken(token);
    }
    return () => setLegacyUserInfo(null);
  }, [token, loginWithToken]);

  useEffect(() => {
    if (token && legacyUserInfo) {
      void skipCheckin(token, legacyUserInfo);
    }
  }, [token, legacyUserInfo, skipCheckin]);

  useEffect(() => {
    if (mealPlanPersistentData && !choosingResult.length) {
      setChoosingResult(JSON.parse(mealPlanPersistentData));
    }
  }, [mealPlanPersistentData, choosingResult]);

  return {
    legacyUserInfo,
    swappableRecipes,
    choosingResult,
    getSwappableRecipes,
    loginWithToken,
    updateChoosingRecipe,
    removeChoosingRecipeByLabel,
    sendChoosingResult,
    clearChoosingRecipe,
    isLoadingSwappableRecipes,
  };
};
