import {
  Auth,
  getAuth,
  onAuthStateChanged,
  signInAnonymously,
  signInWithEmailAndPassword,
  signOut,
  updateProfile,
  User,
} from "firebase/auth";
import React, {
  createContext,
  ReactNode,
  useContext,
  useState,
  useCallback,
} from "react";

import { FirebaseAuthentication } from "@capacitor-firebase/authentication";
import FuturHealthLegacyAPI from "../utils/FuturHealthLegacyAPI";
import { Payload } from "../typings";
import localforage from "localforage";
import { clearPersistedStore } from "../store/persistStore";

export interface AuthContextType {
  currentUser: User | null;
  isAuthenticated: boolean;
  legacyApiUserToken: string | null;
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  logoutAsCanceled: () => Promise<void>;
  getSubscriptionCanceled: () => boolean;
  anonymousLogin: () => Promise<User | null>;
  upgradeAnonUserWithEmailAndPassword: (
    email: string,
    password: string
  ) => Promise<void>;
  updateAuthProfile: (displayName: string) => Promise<void>;
  getLegacyApiUserToken: (user: User) => Promise<void>;
}

export const AuthContext = createContext<AuthContextType | undefined>(
  undefined
);

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [subscriptionCanceled, setSubscriptionCanceled] =
    useState<boolean>(false);
  const [legacyApiUserToken, setLegacyApiUserToken] = useState<string | null>(
    null
  );
  const auth: Auth = getAuth();

  const getLegacyApiUserToken = useCallback(async (user: User) => {
    if (user && user.email && user.displayName) {
      try {
        const parts = user.displayName.split(" ");
        const firstName = parts.shift();
        const lastName = parts.join(" ");
        const apiKey = import.meta.env.VITE_AUTH_API_KEY;
        const payload: Partial<Payload> = {
          email: user.email,
          first_name: firstName,
          last_name: lastName,
          firebase_auth_id: user.uid,
          api_key: apiKey,
        };
        const { data } = await FuturHealthLegacyAPI.getUserToken(payload);
        setLegacyApiUserToken(data.token);
      } catch (error) {
        console.error("error fetching legacy user:", error);
        setLegacyApiUserToken(null);
      }
    }
  }, []);

  const login = useCallback(
    async (email: string, password: string) => {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      setSubscriptionCanceled(false);

      if (userCredential.user) {
        await getLegacyApiUserToken(userCredential.user as User);
      }
    },
    [auth, getLegacyApiUserToken]
  );

  const logout = useCallback(async () => {
    await signOut(auth);
    setCurrentUser(null);
    setLegacyApiUserToken(null);
    localforage.clear().catch(() => {});
    clearPersistedStore();
  }, [auth]);

  const logoutAsCanceled = async () => {
    setSubscriptionCanceled(true);
    await signOut(auth);
  };

  const getSubscriptionCanceled = () => {
    return subscriptionCanceled;
  };

  const anonymousLogin = async () => {
    const auth = getAuth();
    const result = await signInAnonymously(auth);
    return result.user;
  };

  const upgradeAnonUserWithEmailAndPassword = async (
    email: string,
    password: string
  ) => {
    try {
      const result = await FirebaseAuthentication.linkWithEmailAndPassword({
        email,
        password,
      });
      setCurrentUser(result.user as User | null);
    } catch (err) {
      console.error("error upgrading user: ", err);
      throw err;
    }
  };

  const updateAuthProfile = async (displayName: string) =>
    updateProfile(currentUser as User, { displayName }).catch((err) =>
      console.error("error upgrading user: ", err)
    );

  onAuthStateChanged(auth, async (user) => {
    setCurrentUser(user as User);
    setLoading(false);
    if (!legacyApiUserToken && user) await getLegacyApiUserToken(user);
  });

  const value: AuthContextType = {
    currentUser,
    isAuthenticated: currentUser !== null && !currentUser.isAnonymous,
    legacyApiUserToken,
    login,
    logout,
    logoutAsCanceled,
    getSubscriptionCanceled,
    anonymousLogin,
    upgradeAnonUserWithEmailAndPassword,
    updateAuthProfile,
    getLegacyApiUserToken,
  };

  return (
    <AuthContext.Provider value={value}>
      {loading ? <div data-testid="loading-indicator" /> : children}
    </AuthContext.Provider>
  );
};
