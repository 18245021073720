import React, {
  ReactNode,
  createContext,
  useContext,
  useState,
  useMemo,
} from "react";
import { useMealPlan } from "../hooks/useMealPlan";

import { SwapRecipeType, ChoosingRecipe } from "../typings";
import { MealLabels } from "../typings/enums";
import { useGetRecipeFilters } from "../hooks/react-query/useGetRecipeFilters";
import { useGetAllRecipes } from "../hooks/react-query/useGetAllRecipes";
import { useAuth } from "./AuthContext";
import { useGetMealPlanSetup } from "../hooks/react-query/useGetMealPlanSetup";
import { QueryObserverResult, RefetchOptions } from "@tanstack/react-query";
import { useGetMealPlan } from "../hooks/react-query/useGetMealPlan";
import { useGetLegacyApiUser } from "../hooks/react-query/useGetLegacyApiUser";
import { calculateMealPlanDays } from "../services/mealPlans";

export interface NutritionContextProps {
  legacyUserInfo: IUser | null;
  swappableRecipes: SwapRecipeType;
  choosingResult: ChoosingRecipe[];
  getSwappableRecipes: (token: string, weekNumber: number) => Promise<void>;
  loginWithToken: (token: string) => Promise<void>;
  updateChoosingRecipe: (
    choosingRecipes: ChoosingRecipe[],
    isSelect: boolean
  ) => void;
  removeChoosingRecipeByLabel: (label: MealLabels) => void;
  sendChoosingResult: (weekNumber: number) => Promise<void>;
  clearChoosingRecipe: () => void;
  recipeFilterIds: Set<number>;
  setRecipeFilterIds: React.Dispatch<React.SetStateAction<Set<number>>>;
  setIsFiltersUpdatedModalVisible: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  isFiltersUpdatedModalVisible: boolean;
  recipes: {
    isLoadingRecipes: boolean;
    recipesData: IRecipeInLibrary[] | undefined;
    recipesError: Error | null;
    setShouldFetchRecipes: React.Dispatch<React.SetStateAction<boolean>>;
  };
  recipeFilters: {
    isLoadingRecipeFilters: boolean;
    recipeFilters: IRecipeFilter[] | undefined;
    recipeFiltersError: Error | null;
  };
  isMealPlanSetupLoading: boolean;
  isMealPlanSetup?: boolean;
  refetchIsMealPlanSetup: (
    options?: RefetchOptions
  ) => Promise<QueryObserverResult<boolean, Error>>;
  isLoadingSwappableRecipes: boolean;
  isMealPlanLoading: boolean;
  mealPlan: IMealPlan[] | [];
  isLegacyApiUserLoading: boolean;
  legacyApiUserData: IAuth | null;
  refreshMealPlan: (
    options?: RefetchOptions
  ) => Promise<QueryObserverResult<IMealPlan[], Error>>;
  swappingMeal: ISwappingMeal | null;
  setSwappingMeal: React.Dispatch<React.SetStateAction<ISwappingMeal | null>>;
  mealPlanWeeks: string[][];
  mealPlanDays: string[];
  chosenRecipeInLibrary: IRecipeInLibrary | null;
  setChosenRecipeInLibrary: React.Dispatch<
    React.SetStateAction<IRecipeInLibrary | null>
  >;
}

const NutritionContext = createContext<NutritionContextProps | undefined>(
  undefined
);

export const useNutritionContext = (): NutritionContextProps => {
  const context = useContext(NutritionContext);
  if (!context) {
    throw new Error("useNutrition must be used within a NutritionProvider");
  }
  return context;
};

interface NutritionProviderProps {
  children: ReactNode;
}

export const NutritionProvider: React.FC<NutritionProviderProps> = ({
  children,
}) => {
  const [swappingMeal, setSwappingMeal] = useState<ISwappingMeal | null>(null);
  const [chosenRecipeInLibrary, setChosenRecipeInLibrary] =
    useState<IRecipeInLibrary | null>(null);
  const {
    isLoading: isLoadingRecipeFilters,
    data: recipeFiltersData,
    error: recipeFiltersError,
  } = useGetRecipeFilters();
  const {
    isLoading: isLoadingRecipes,
    data: recipesData,
    error: recipesError,
    setShouldFetch: setShouldFetchRecipes,
  } = useGetAllRecipes();
  const {
    isLoading: isMealPlanSetupLoading,
    data: isMealPlanSetup,
    refetch: refetchIsMealPlanSetup,
  } = useGetMealPlanSetup();
  const { isLoading: isUserLoading, data: userDataResponse } =
    useGetLegacyApiUser(isMealPlanSetup);

  const [recipeFilterIds, setRecipeFilterIds] = useState(new Set<number>());
  const [isFiltersUpdatedModalVisible, setIsFiltersUpdatedModalVisible] =
    useState(false);

  const userData = useMemo(() => {
    if (!userDataResponse || !userDataResponse.data) {
      return null;
    }

    return userDataResponse.data;
  }, [userDataResponse]);

  const { legacyApiUserToken } = useAuth();

  const useMealPlanValue = useMealPlan(legacyApiUserToken);

  const { weeks, days } = useMemo(() => {
    const user = userData?.user;

    if (
      !user ||
      !user?.first_checkin ||
      !user?.last_checkin ||
      !user?.first_checkin?.created_at ||
      !user?.last_checkin?.created_at
    ) {
      return {
        weeks: [],
        days: [],
      };
    }
    return calculateMealPlanDays({
      first_checkin: user?.first_checkin?.created_at,
      last_checkin: user?.last_checkin?.created_at,
    });
  }, [userData]);

  const {
    isLoading: isMealPlanLoading,
    data: mealPlan = [],
    refetch: refetchMealPlan,
  } = useGetMealPlan(isMealPlanSetup);

  const getMealPlanWithWeekNumbers = (mealPlanData: IMealPlan[]) => {
    return mealPlanData.map((week, weekIndex) => {
      return {
        ...week,
        weekNumber: weekIndex,
        days: week.days.map((day) => ({
          ...day,
          weekNumber: weekIndex,
        })),
      };
    });
  };

  const normalizedMealPlan = useMemo(() => {
    if (!mealPlan?.length) {
      return [];
    }

    if (mealPlan.length > weeks.length && mealPlan.length === 3) {
      const mealPlanData = [mealPlan[1], mealPlan[2]];
      return getMealPlanWithWeekNumbers(mealPlanData);
    } else {
      return getMealPlanWithWeekNumbers(mealPlan);
    }
  }, [mealPlan, weeks.length]);

  const nutritionContextValue = {
    ...useMealPlanValue,
    recipeFilterIds,
    setRecipeFilterIds,
    setIsFiltersUpdatedModalVisible,
    isFiltersUpdatedModalVisible,
    recipeFilters: {
      isLoadingRecipeFilters,
      recipeFilters: recipeFiltersData?.data,
      recipeFiltersError,
    },
    recipes: {
      isLoadingRecipes,
      recipesData: recipesData?.data,
      recipesError,
      setShouldFetchRecipes,
    },
    isMealPlanSetupLoading,
    isMealPlanSetup,
    refetchIsMealPlanSetup,
    isMealPlanLoading,
    mealPlan: normalizedMealPlan,
    mealPlanWeeks: weeks,
    mealPlanDays: days,
    refreshMealPlan: refetchMealPlan,
    isLegacyApiUserLoading: isUserLoading,
    legacyApiUserData: userData,
    swappingMeal,
    setSwappingMeal,
    chosenRecipeInLibrary,
    setChosenRecipeInLibrary,
  };

  return (
    <NutritionContext.Provider value={nutritionContextValue}>
      {children}
    </NutritionContext.Provider>
  );
};
