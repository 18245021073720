import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { storage } from "../hooks/storageService";

interface StorageContextProps {
  isStorageReady: boolean;
  setItem: (key: string, value: unknown) => Promise<void>;
  getItem: (key: string) => Promise<unknown>;
}

const StorageContext = createContext<StorageContextProps | undefined>(
  undefined
);

export const useStorage = (): StorageContextProps => {
  const context = useContext(StorageContext);
  if (!context) {
    throw new Error("useStorage must be used within a StorageProvider");
  }
  return context;
};

interface StorageProviderProps {
  children: ReactNode;
}

export const StorageProvider: React.FC<StorageProviderProps> = ({
  children,
}) => {
  const [isStorageReady, setIsStorageReady] = useState(false);

  useEffect(() => {
    const init = async () => {
      await storage.create();
      setIsStorageReady(true);
    };
    init();
  }, []);

  const setItem = async (key: string, value: unknown): Promise<void> => {
    await storage.set(key, value);
  };

  const getItem = async (key: string): Promise<unknown> => {
    return await storage.get(key);
  };

  return (
    <StorageContext.Provider value={{ isStorageReady, setItem, getItem }}>
      {children}
    </StorageContext.Provider>
  );
};
