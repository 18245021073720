import { useQuery } from "@tanstack/react-query";
import FuturHealthLegacyAPI from "../../utils/FuturHealthLegacyAPI";
import { useAuth } from "../../context/AuthContext";

export const useGetAllCheckin = () => {
  const { legacyApiUserToken } = useAuth();
  return useQuery({
    queryKey: ["legacy api", "get all checkins"],
    queryFn: async () =>
      await FuturHealthLegacyAPI.getAllCheckins(legacyApiUserToken ?? ""),
    enabled: !!legacyApiUserToken,
  });
};
