import { useContext, useEffect, useMemo, useState } from "react";
import { colors } from "../theme/colors";
import { Grid, Loader, Paper } from "@mantine/core";
import { useProgramAvailabilitiesQuery } from "../hooks/react-query/useConsultAvailabilitiesQuery";
import { useParams } from "react-router-dom";
import { removeDuplicateDates } from "../utils/helpers";
import AppointmentDateSelect from "../components/AppointmentDateSelect";
import AppointmentTimeSelect from "../components/AppointmentTimeSelect";
import EditTimezone from "../components/EditTimezone";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { useStorage } from "../context/StorageContext";
import { FHPage } from "../utils/pageUtils";
import { useLatestConsultRealtime } from "../hooks/react-query/useLatestConsultRealtime";
import { useToast } from "../context/ToastContext";
import { NavContext } from "@ionic/react";
import { ConsultType } from "../typings/enums";
import "./AppointmentScheduler.css";

dayjs.extend(utc);
dayjs.extend(timezone);

interface Params {
  index: string;
  selectedDate?: string;
}

type Props = {
  selectDateProps?: {
    pageTitle: string;
    title: string;
    description: string;
  };
  selectTimeProps?: {
    pageTitle: string;
  };
  cancelPreviousConsult?: boolean;
};

const DEFAULT_SELECT_DATE_PROPS = {
  pageTitle: "SCHEDULE",
  title: "Schedule Your Appointment",
  description: "",
};

const DEFAULT_SELECT_TIME_PROPS = {
  pageTitle: "TIMEZONE",
};

const AppointmentScheduler = ({
  selectDateProps = DEFAULT_SELECT_DATE_PROPS,
  selectTimeProps = DEFAULT_SELECT_TIME_PROPS,
  cancelPreviousConsult,
}: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { index, selectedDate } = useParams<Params>();
  const { isStorageReady, getItem } = useStorage();
  const [defaultTz, setDefaultTz] = useState<string>(dayjs.tz.guess());
  useEffect(() => {
    if (isStorageReady) {
      getItem("defaultTz").then((val) => {
        if (val) {
          setDefaultTz(val as string);
        }
      });
    }
  });

  const { addToast } = useToast();
  const navContext = useContext(NavContext);

  const { data: latestConsult } = useLatestConsultRealtime([
    ConsultType.scheduled_video_visit,
    ConsultType.followup_video_visit,
  ]);

  const { data: programData, isLoading: availabilityLoading } =
    useProgramAvailabilitiesQuery(
      latestConsult?.usState as string,
      latestConsult?.consultType as string
    );

  const availabilityDates = useMemo(() => {
    if (!programData) return [];
    const dates: dayjs.Dayjs[] = programData.availability.map(
      (dateStr: string) => {
        return dayjs.tz(dateStr, defaultTz).startOf("D");
      }
    );

    return removeDuplicateDates(dates);
  }, [programData, defaultTz]);

  const handleSuccess = () => {
    navContext.navigate("/tabs/home");
    addToast(
      cancelPreviousConsult
        ? "Appointment Rescheduled"
        : "Appointment Scheduled",
      "success"
    );
  };

  const renderCurrentScreen = () => {
    if (availabilityLoading || isLoading) {
      return (
        <Paper radius="md">
          <Grid justify="center">
            <Loader size="lg" mt="xl" color={colors.blurple}></Loader>
          </Grid>
        </Paper>
      );
    }
    switch (index) {
      case "0":
        return (
          <AppointmentDateSelect
            availabilityDates={availabilityDates}
            consultId={latestConsult?.guid ?? ""}
            defaultTz={defaultTz}
            title={selectDateProps.title}
            description={selectDateProps.description}
          />
        );
      case "1":
        return (
          <AppointmentTimeSelect
            currentTz={defaultTz}
            selectedDate={selectedDate ?? ""}
            availability={programData?.availability}
            consult={latestConsult}
            setIsLoading={setIsLoading}
            onSuccess={handleSuccess}
            cancelPreviousConsult={cancelPreviousConsult}
          />
        );
      case "2":
        return <EditTimezone currentTimezone={defaultTz} />;
    }
  };

  return (
    <FHPage
      title={
        index === "2" ? selectTimeProps.pageTitle : selectDateProps.pageTitle
      }
      withBackground={false}
    >
      {renderCurrentScreen()}
    </FHPage>
  );
};

export default AppointmentScheduler;
