import { IonToast } from "@ionic/react";
import { createContext, useContext, useState } from "react";
import "./ToastStyles.css";
import { getToastIcon } from "../utils/icons";

export type ToastType = "success" | "error" | "warning" | "info";

type Toast = {
  id: number;
  message: string;
  duration: number;
  type: ToastType;
};

type ToastContextType = {
  addToast: (message: string, type: ToastType, duration?: number) => void;
};

const ToastContext = createContext<ToastContextType | undefined>(undefined);

export const useToast = (): ToastContextType => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error("useToast must be used within a ToastProvider");
  }
  return context;
};

type ToastProviderProps = {
  children: React.ReactNode;
};

export const ToastProvider = ({ children }: ToastProviderProps) => {
  const [toasts, setToasts] = useState<Toast[]>([]);

  const addToast = (message: string, type: ToastType, duration = 3000) => {
    const id = Date.now();
    setToasts([...toasts, { id, message, duration, type }]);
  };

  const removeToast = (id: number) => {
    setToasts(toasts.filter((toast) => toast.id !== id));
  };

  return (
    <ToastContext.Provider value={{ addToast }}>
      {children}
      {toasts.map((toast, i) => (
        <IonToast
          cssClass={"fh-toast"}
          key={toast.id}
          isOpen={true}
          message={toast.message}
          duration={toast.duration}
          onDidDismiss={() => removeToast(toast.id)}
          position="top"
          data-testid={`toast-message-${i + 1}`}
          icon={getToastIcon(toast.type)}
        />
      ))}
    </ToastContext.Provider>
  );
};
