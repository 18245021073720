import { useEffect, useRef } from "react";
import { LDFlagSet, initialize, LDClient } from "launchdarkly-js-client-sdk";
import { datadogRum } from "@datadog/browser-rum";
import { useIonViewWillLeave } from "@ionic/react";
import { useAuth } from "../context/AuthContext";

interface UseLaunchDarklyProps {
  clientSideId: string;
}

// Hook to initialize LaunchDarkly with Datadog integration
const useLaunchDarkly = ({ clientSideId }: UseLaunchDarklyProps): void => {
  // Use a ref to store the LaunchDarkly client to avoid unnecessary re-initialization
  const { currentUser } = useAuth();
  const key = currentUser?.uid || "anonymous-user";
  const clientRef = useRef<LDClient | null>(null);

  useEffect(() => {
    // Initialize client if it doesn't exist
    if (!clientRef.current) {
      clientRef.current = initialize(clientSideId, { key });

      clientRef.current.on("ready", () => {
        const allFlags: LDFlagSet = clientRef.current!.allFlags();

        // Add each flag to Datadog RUM with addFeatureFlagEvaluation
        Object.entries(allFlags).forEach(([flagKey, flagValue]) => {
          datadogRum.addFeatureFlagEvaluation(flagKey, flagValue);
        });

        console.log("LaunchDarkly initialized with flags:", allFlags);
      });
    }

    // Cleanup function
    const cleanup = () => {
      if (clientRef.current) {
        try {
          clientRef.current.close();
          clientRef.current = null;
          console.log("LaunchDarkly client closed.");
        } catch (error) {
          console.warn("Error closing LaunchDarkly client:", error);
        }
      }
    };

    // Cleanup when component unmounts
    return () => cleanup();
  }, [clientSideId, key]);

  // Cleanup when the Ionic view is about to leave
  useIonViewWillLeave(() => {
    if (clientRef.current) {
      try {
        clientRef.current.close();
        clientRef.current = null;
        console.log("LaunchDarkly client closed on view leave.");
      } catch (error) {
        console.warn("Error closing LaunchDarkly client on view leave:", error);
      }
    }
  });
};

export default useLaunchDarkly;
