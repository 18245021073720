import { App as CapApp } from "@capacitor/app";
import {
  IonApp,
  IonRouterOutlet,
  NavContext,
  setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import React, { lazy, Suspense, useContext, useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import { StorageProvider } from "./context/StorageContext";
import { ToastProvider } from "./context/ToastContext";
import { ZendeskProvider } from "./context/ZendeskContext";
import { initStorage } from "./hooks/storageService";
import { NutritionProvider } from "./context/NutritionContext";

/* Lazy Load Components */
const ResetPassword = lazy(() => import("./components/ResetPassword"));
const ActivateNotifications = lazy(
  () => import("./pages/ActivateNotifications")
);
const AfterVisitSummaries = lazy(() => import("./pages/AfterVisitSummaries"));
const AfterVisitSummaryDetails = lazy(
  () => import("./pages/AfterVisitSummaryDetails")
);
const AppointmentDetails = lazy(() => import("./pages/AppointmentDetails"));
const CheckoutReturn = lazy(() => import("./pages/CheckoutReturn"));
const CreateAccount = lazy(() => import("./pages/CreateAccount"));
const CreateApptSwitcher = lazy(
  () => import("./pages/create-appointment/CreateApptSwitcher")
);
const Disqualified = lazy(() => import("./pages/Disqualified"));
const Login = lazy(() => import("./pages/Login"));
const MedicationInstructions = lazy(
  () => import("./pages/MedicationInstructions")
);
const MedicationOrderDetails = lazy(
  () => import("./pages/MedicationOrderDetails")
);
const Messages = lazy(() => import("./pages/Messages"));
const NotificationSettings = lazy(() => import("./pages/NotificationSettings"));
const Notifications = lazy(() => import("./pages/Notifications"));
const PostSurveySignUp = lazy(() => import("./pages/PostSurveySignUp"));
const PrescriptionDetails = lazy(() => import("./pages/PrescriptionDetails"));
const Rate = lazy(() => import("./pages/Rate"));
const ReportSideEffects = lazy(() => import("./pages/ReportSideEffects"));
const RescheduleAppt = lazy(() => import("./pages/RescheduleAppt"));
const SideEffectDetails = lazy(() => import("./pages/SideEffectDetails"));
const StartLabWorkPage = lazy(() => import("./pages/StartLabWork"));
const TreatmentPlan = lazy(() => import("./pages/TreatmentPlan"));
const PaymentMethodsPage = lazy(
  () => import("./pages/account/PaymentMethodsPage")
);
const Account = lazy(() => import("./tabs/Account"));
const Tabs = lazy(() => import("./tabs/Tabs"));
const AddNewCard = lazy(() => import("./pages/AddNewCard"));
const MedicalCitations = lazy(() => import("./pages/MedicalCitations"));
const MembershipManagement = lazy(() => import("./pages/MembershipManagement"));
const NutritionIntroPage = lazy(() => import("./pages/NutritionIntroPage"));
const MealCardPage = lazy(() => import("./pages/nutrition/MealCardPage"));
const RecipeLibrary = lazy(
  () => import("./pages/nutrition/recipeLibrary/RecipeLibrary")
);
const RecipesFilters = lazy(
  () => import("./pages/nutrition/recipeLibrary/RecipesFilters")
);
const RecipeInLibraryCard = lazy(
  () => import("./pages/nutrition/recipeLibrary/RecipeInLibraryCard")
);
const RefillIntake = lazy(() => import("./pages/RefillIntake"));
const AddShippingAddress = lazy(() => import("./pages/AddShippingAddress"));
const SurveyQuestion = lazy(() => import("./pages/survey/SurveyQuestion"));
const PersonalDetails = lazy(
  () => import("./pages/personal-details/PersonalDetails")
);

import "./theme/variables.css";

import "@mantine/dates/styles.css";
import "@mantine/charts/styles.css";
// Customer google typeface
import "typeface-inter";
import "typeface-syne";
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";
import "@mantine/code-highlight/styles.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/padding.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";

/* Theme variables */
import { SplashScreen } from "@capacitor/splash-screen";
import "@mantine/carousel/styles.css";
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "./theme/variables.css";
import { LDProvider } from "launchdarkly-react-client-sdk";
import ScheduleAppt from "./pages/ScheduleAppt";
import useLaunchDarkly from "./hooks/useLaunchDarkly";
import PrivateRoute from "./components/PrivateRoute";
import { FHLoader } from "./components/FHLoader";

const launchDarklyClientSideID = import.meta.env.VITE_LAUNCHDARKLY_CLIENT_ID;
setupIonicReact({
  animated: true, //!isPlatform('mobileweb'),
});

const queryClient = new QueryClient();

const App: React.FC = () => {
  const navContext = useContext(NavContext);

  useEffect(() => {
    SplashScreen.hide();
  }, []);

  useEffect(() => {
    initStorage();
  }, []);

  const { navigate } = navContext;

  useEffect(() => {
    CapApp.addListener("appUrlOpen", (data) => {
      console.log("App opened with URL: ", data.url);
      const path = new URL(data.url).pathname;
      navigate(path);
    });
  }, [navigate]);

  // Initialize LaunchDarkly
  useLaunchDarkly({
    clientSideId: launchDarklyClientSideID,
  });

  return (
    <IonApp>
      <LDProvider clientSideID={launchDarklyClientSideID}>
        <QueryClientProvider client={queryClient}>
          <NutritionProvider>
            <StorageProvider>
              <ZendeskProvider>
                <IonReactRouter>
                  <ToastProvider>
                    <Suspense fallback={<FHLoader />}>
                      <IonRouterOutlet>
                        {/* Authed routes */}
                        <PrivateRoute exact path="/rate" component={Rate} />
                        <PrivateRoute
                          exact
                          path="/treatment-plan/:prescriptionId"
                          component={TreatmentPlan}
                        />
                        <PrivateRoute
                          exact
                          path="/account/"
                          component={Account}
                        />
                        <PrivateRoute
                          exact
                          path="/medication-instructions/:medicationId"
                          component={MedicationInstructions}
                        />
                        <PrivateRoute
                          exact
                          path="/prescription-details/:medicationId"
                          component={PrescriptionDetails}
                        />
                        <PrivateRoute
                          exact
                          path="/after-visit-summaries"
                          component={AfterVisitSummaries}
                        />
                        <PrivateRoute
                          exact
                          path="/create-appointment/:index/:consultId?/:episodeId?"
                          component={CreateApptSwitcher}
                        />
                        <PrivateRoute
                          exact
                          path="/schedule-appointment/:index/:selectedDate?"
                          component={ScheduleAppt}
                        />
                        <PrivateRoute
                          exact
                          path="/reschedule-appointment/:index/:selectedDate?"
                          component={RescheduleAppt}
                        />
                        <PrivateRoute path="/tabs" component={Tabs} />
                        <PrivateRoute
                          exact
                          path="/checkout/return"
                          component={CheckoutReturn}
                        />
                        <PrivateRoute
                          path="/appointment/details/:guid"
                          component={AppointmentDetails}
                        />
                        <PrivateRoute
                          path="/medication-order/:status/:orderNumber?"
                          component={MedicationOrderDetails}
                        />
                        <PrivateRoute
                          path="/side-effects"
                          component={SideEffectDetails}
                        />
                        <PrivateRoute
                          path="/start-lab-work/:guid?"
                          component={StartLabWorkPage}
                          exact
                        />
                        <PrivateRoute
                          path="/report-side-effects"
                          component={ReportSideEffects}
                        />
                        <PrivateRoute
                          path="/after-visit-summaries/details/:consultId"
                          component={AfterVisitSummaryDetails}
                          exact
                        />
                        <PrivateRoute
                          exact
                          path="/notifications"
                          component={Notifications}
                        />
                        <PrivateRoute
                          exact
                          path="/notification-settings"
                          component={NotificationSettings}
                        />
                        <PrivateRoute
                          exact
                          path="/nutrition-quiz"
                          component={NutritionIntroPage}
                        />
                        <PrivateRoute
                          path="/personal-details"
                          component={PersonalDetails}
                        />
                        <PrivateRoute
                          path="/reset-password"
                          component={ResetPassword}
                        />
                        <PrivateRoute
                          path="/add-shipping-address"
                          component={AddShippingAddress}
                        />
                        <PrivateRoute path="/messages" component={Messages} />
                        <PrivateRoute
                          exact
                          path="/add-new-card"
                          component={AddNewCard}
                        />
                        <PrivateRoute
                          exact
                          path="/medical-citations"
                          component={MedicalCitations}
                        />
                        <PrivateRoute
                          exact
                          path="/membership-management"
                          component={MembershipManagement}
                        />
                        <PrivateRoute
                          path="/refill-funnel/:id/:index"
                          component={RefillIntake}
                          exact
                        />
                        {/* Publicly accessible routes */}
                        <Route
                          path="/survey/:slug/:index"
                          component={SurveyQuestion}
                        />
                        <Route path="/disqualified" component={Disqualified} />
                        <Route
                          exact
                          path="/create-account"
                          component={CreateAccount}
                        />
                        <Route
                          exact
                          path="/activate-notifications"
                          component={ActivateNotifications}
                        />
                        <Route exact path="/login" component={Login} />
                        <Route
                          exact
                          path="/sign-up"
                          component={PostSurveySignUp}
                        />
                        <Route
                          exact
                          path="/payment-methods"
                          component={PaymentMethodsPage}
                        />
                        <PrivateRoute
                          exact
                          path="/nutrition-meal-card/:mealIndex/:date/:weekNumberInString/:weekDay"
                          component={MealCardPage}
                        />
                        <PrivateRoute
                          exact
                          path="/recipes-library/:swapMealId/:swapMealLabel/:selectedWeek/"
                          component={RecipeLibrary}
                        />
                        <PrivateRoute
                          exact
                          path="/recipe-card"
                          component={RecipeInLibraryCard}
                        />
                        <PrivateRoute
                          exact
                          path="/recipes-filters"
                          component={RecipesFilters}
                        />
                        <Route exact path="/">
                          <Redirect to="/tabs" />
                        </Route>
                      </IonRouterOutlet>
                    </Suspense>
                  </ToastProvider>
                </IonReactRouter>
              </ZendeskProvider>
            </StorageProvider>
          </NutritionProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </LDProvider>
    </IonApp>
  );
};

export default App;
