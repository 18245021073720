import { Text, Flex, Paper, Stack, Title, Button } from "@mantine/core";
import { colors } from "../theme/colors";
import {
  convertUTCToSpecifiedTimezone,
  filterDatesByGivenDayjs,
  getReadableTimeZone,
} from "../utils/helpers";
import { useContext, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEarthAmericas } from "@fortawesome/pro-regular-svg-icons";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import FHConfirmButton from "./FHConfirmButton";
import { useAuth } from "../context/AuthContext";
import { NavContext } from "@ionic/react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import {
  STEADY_MD_PROGRAM_GUID,
  createSteadymdConsult,
  scheduleSteadymdConsult,
  updateSteadymdConsultStatus,
} from "../services/steadymdService";
import {
  Consult,
  ConsultCreateUsStateEnum,
  ConsultVisitStatusEnum,
} from "@futurhealth/steadymd-api-client";
import { useQueryClient } from "@tanstack/react-query";
import { useIsMobile } from "../hooks/useIsMobile";
import { useLocation } from "react-router";

dayjs.extend(utc);
dayjs.extend(timezone);

interface AppointmentTimeSelectProps {
  selectedDate: string;
  availability: string[];
  consult: Partial<Consult> | null | undefined;
  currentTz: string;
  cancelPreviousConsult?: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  onSuccess?: () => void;
}

const AppointmentTimeSelect = ({
  selectedDate,
  availability,
  consult,
  currentTz,
  cancelPreviousConsult,
  setIsLoading,
  onSuccess,
}: AppointmentTimeSelectProps) => {
  const navContext = useContext(NavContext);
  const { currentUser, anonymousLogin } = useAuth();
  const queryClient = useQueryClient();
  const isMobile = useIsMobile();
  const [confirmIndex, setConfirmIndex] = useState<number>(-1);
  const location = useLocation();
  const urlRoot = location.pathname.split("/")[1];

  const availabileTimesForDate = useMemo(() => {
    return filterDatesByGivenDayjs(
      availability,
      dayjs(Number(selectedDate)).tz(currentTz),
      currentTz
    );
  }, [availability, currentTz, selectedDate]);

  const handleSelectAppt = async (time: string) => {
    if (!currentUser) {
      await anonymousLogin();
    } else {
      const token = await currentUser.getIdToken();
      setIsLoading(true);

      const StatusesToCancel: string[] = [
        ConsultVisitStatusEnum.Assigned,
        ConsultVisitStatusEnum.Unassigned,
        ConsultVisitStatusEnum.Scheduled,
        ConsultVisitStatusEnum.WaitingToSchedule,
      ];

      try {
        if (
          cancelPreviousConsult &&
          consult?.guid &&
          StatusesToCancel.indexOf(consult.visitStatus ?? "") !== -1
        ) {
          await updateSteadymdConsultStatus(
            consult?.guid,
            ConsultVisitStatusEnum.Canceled,
            token
          );
        }

        const newConsult = await createSteadymdConsult(
          {
            programGuid: STEADY_MD_PROGRAM_GUID,
            usState: consult?.usState as ConsultCreateUsStateEnum,
            consultType: consult?.consultType,
            reasonForVisit: consult?.reasonForVisit,
            emrUri: consult?.emrUri ?? "",
          },
          token
        );

        await scheduleSteadymdConsult(
          newConsult.guid as string,
          time,
          dayjs.tz.guess(),
          token
        );
        queryClient.invalidateQueries({ queryKey: ["latest consult"] });
        onSuccess?.();
      } catch (err) {
        console.error(err);
        throw err;
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleTzEdit = () => {
    navContext.navigate(`/${urlRoot}/2/${consult?.guid}`, "forward");
    setConfirmIndex(-1);
  };

  return (
    <Paper radius="md" my={"lg"}>
      <Flex justify={isMobile ? "flex-start" : "center"}>
        <Title fz="28px" fw={600} c={colors.black} lh="36px">
          Select a Time
        </Title>
      </Flex>
      <Flex justify={isMobile ? "flex-start" : "center"} mt="lg">
        <Text fz="16px" c={colors.nearBlack} lh="24px">
          {`Available appointments for ${new Date(Number(selectedDate)).toLocaleDateString()}.`}
        </Text>
      </Flex>
      <Flex
        justify={isMobile ? "space-between" : "center"}
        align="center"
        mt="lg"
      >
        <Text fz="16px" c={colors.darkGray}>
          <FontAwesomeIcon
            icon={faEarthAmericas}
            size="xs"
            style={{ marginRight: "7px" }}
          />
          Timezone
        </Text>
        <Button
          rightSection={<FontAwesomeIcon icon={faChevronRight} />}
          variant="transparent"
          c={colors.nearBlack}
          onClick={() => handleTzEdit()}
        >
          {getReadableTimeZone(currentTz)}
        </Button>
      </Flex>
      <Stack justify="center" align="center" gap="lg" mt="lg">
        {availabileTimesForDate?.map((time, i) => (
          <FHConfirmButton
            key={i}
            index={i}
            confirmIndex={confirmIndex}
            setConfirmIndex={setConfirmIndex}
            onConfirm={() => handleSelectAppt(time)}
          >
            {convertUTCToSpecifiedTimezone(time, currentTz)}
          </FHConfirmButton>
        ))}
      </Stack>
    </Paper>
  );
};

export default AppointmentTimeSelect;
