import { useMemo } from "react";
import FHButton from "./FHButton";
import { Flex } from "@mantine/core";
import { useIsMobile } from "../hooks/useIsMobile";

interface FHConfirmButtonProps {
  children: React.ReactNode;
  index: number;
  confirmIndex: number;
  setConfirmIndex: React.Dispatch<React.SetStateAction<number>>;
  selected?: boolean;
  onConfirm?: () => void;
}

const FHConfirmButton = ({
  children,
  onConfirm,
  index,
  confirmIndex,
  setConfirmIndex,
  selected,
}: FHConfirmButtonProps) => {
  const isMobile = useIsMobile();
  const showConfirm = confirmIndex === index;

  const mainBtnVariant = useMemo(() => {
    if (selected && !showConfirm) {
      return "primary";
    }
    if (showConfirm) {
      return "tertiary";
    }
    return "secondary";
  }, [showConfirm, selected]);

  return (
    <Flex w={isMobile ? "100%" : "50%"} justify="center" gap="sm" maw="500px">
      <FHButton
        dataTestId="select-time-button"
        variant={mainBtnVariant}
        onClick={() => setConfirmIndex(index)}
        fullWidth
      >
        {children}
      </FHButton>
      {showConfirm && (
        <FHButton
          variant="primary"
          dataTestId="confirm-button"
          onClick={onConfirm}
          fullWidth
        >
          Confirm
        </FHButton>
      )}
    </Flex>
  );
};

export default FHConfirmButton;
