import { StateCreator, create } from "zustand";
import { persist } from "zustand/middleware";
import localforage from "localforage";

interface PersistedStore {
  isFunnelQuizCompleted: boolean;
  setIsFunnelQuizCompleted: (isFunnelQuizCompleted: boolean) => void;
}

const storage = {
  getItem: async (name: string) => {
    const value = await localforage.getItem(name);
    return value ? JSON.parse(value as string) : null;
  },
  setItem: async (name: string, value: unknown) => {
    await localforage.setItem(name, JSON.stringify(value));
  },
  removeItem: async (name: string) => {
    await localforage.removeItem(name);
  },
};

export const usePersistStore = create<PersistedStore>(
  persist(
    (set) => ({
      isFunnelQuizCompleted: false,
      setIsFunnelQuizCompleted: (isFunnelQuizCompleted) =>
        set({ isFunnelQuizCompleted }),
    }),
    {
      name: "funnel-quiz-state",
      storage, // Use localforage for storage
    }
  ) as StateCreator<PersistedStore>
);

export const clearPersistedStore = async () => {
  try {
    await localforage.removeItem("funnel-quiz-state");
    usePersistStore.setState({ isFunnelQuizCompleted: false });
  } catch (error) {
    console.error("Failed to clear persisted store:", error);
  }
};
