import { useEffect } from "react";
import { datadogRum } from "@datadog/browser-rum";

interface UseDatadogRumProps {
  appVersion: string;
  environment: string;
}

const useDatadogRum = ({ appVersion, environment }: UseDatadogRumProps) => {
  useEffect(() => {
    if (environment === "development" || environment === "local") {
      console.log(
        "Datadog RUM is disabled in development or local environment."
      );
      return;
    }

    datadogRum.init({
      applicationId: "ad2214ca-7d9d-4e5d-82c5-dfa7af0d6693",
      clientToken: "pubaafb5d055ecc2adf8587cd606181c44a",
      site: "us5.datadoghq.com",
      service: "futurhealth-plus",
      env: environment,
      version: appVersion,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "mask-user-input",
    });

    console.log(
      `Datadog RUM initialized for environment: ${environment}, version: ${appVersion}`
    );
  }, [appVersion, environment]);

  return datadogRum;
};

export default useDatadogRum;
