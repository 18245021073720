import FuturHealthLegacyAPI from "../utils/FuturHealthLegacyAPI";
import { get } from "lodash";
import dayjs from "dayjs";
import { chunk } from "../utils/helpers";
import { WeekInfoType } from "../typings";

export const getAllMealPlans = async (token: string | null) => {
  if (!token) {
    throw new Error("No token provided");
  }

  const { data: weekInfo } = await FuturHealthLegacyAPI.getWeekInfo(token);

  const prevCheckin = weekInfo?.previous?.checkin;
  const currentWeekNumber = weekInfo?.current.week_number ?? 1;

  const realPrevWeekNumber = getRealPrevWeekNumber(weekInfo, currentWeekNumber);
  const realNextWeekNumber = getRealNextWeekNumber(weekInfo, currentWeekNumber);

  const dataAll = await Promise.all([
    FuturHealthLegacyAPI.getMealPlan({
      token,
      data: {
        weekNumber: prevCheckin ? undefined : realPrevWeekNumber,
        dayNumber: undefined,
        checkin: prevCheckin,
      },
    }),
    FuturHealthLegacyAPI.getMealPlan({
      token,
    }),
    FuturHealthLegacyAPI.getMealPlan({
      token,
      data: {
        weekNumber: realNextWeekNumber,
      },
    }),
  ]);

  const [previousWeekResponse, currentWeekResponse, nextWeekResponse] = dataAll;

  return [
    previousWeekResponse.data,
    currentWeekResponse.data,
    nextWeekResponse.data,
  ];
};

export const calculateMealPlanDays = ({
  first_checkin,
  last_checkin,
}: {
  first_checkin: string;
  last_checkin: string;
}): { weeks: string[][]; days: string[] } => {
  const firstCheckin = first_checkin;
  const lastCheckin = last_checkin;

  const firstCheckinAt = dayjs(firstCheckin).format("YYYY-MM-DD");
  const lastCheckinAt = dayjs(lastCheckin).format("YYYY-MM-DD");

  const downLimit =
    lastCheckinAt !== firstCheckinAt
      ? dayjs(lastCheckinAt).subtract(1, "w").format("YYYY-MM-DD")
      : dayjs(lastCheckinAt).format("YYYY-MM-DD");
  const upLimit = dayjs(lastCheckinAt)
    .add(2, "w")
    .subtract(1, "d")
    .format("YYYY-MM-DD");

  const totalDays = dayjs(upLimit).diff(dayjs(downLimit), "day") + 1;
  const daysShifted = Array.from({ length: totalDays }, (_, index) =>
    dayjs(downLimit).add(index, "day").format("YYYY-MM-DD")
  );

  const weeks = chunk(daysShifted, 7);

  return { weeks, days: daysShifted };
};

export const getPrevWeekNumberByCurrent = (curWeekNumber: number) =>
  curWeekNumber - 1 < 3 ? 12 : curWeekNumber - 1;
export const getNextWeekNumberByCurrent = (curWeekNumber: number) =>
  curWeekNumber + 1 > 12 ? 3 : curWeekNumber + 1;

export const getRealPrevWeekNumber = (
  weekInfo: WeekInfoType | null,
  currentWeekNumber: number
) => {
  return get(
    weekInfo,
    "previous.week_number",
    getPrevWeekNumberByCurrent(currentWeekNumber)
  );
};

export const getRealNextWeekNumber = (
  weekInfo: WeekInfoType | null,
  currentWeekNumber: number
) => {
  return get(
    weekInfo,
    "next.week_number",
    getNextWeekNumberByCurrent(currentWeekNumber)
  );
};

export const getRealCurrentWeekNumber = (legacyUserInfo: IUser | null) => {
  const curWeekNumber =
    legacyUserInfo &&
    legacyUserInfo.last_checkin &&
    legacyUserInfo?.last_checkin.week_number > 0
      ? legacyUserInfo.last_checkin.week_number
      : 1;

  return curWeekNumber;
};
