import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

export const toTitleCase = (str: string) => {
  return str
    .split(" ")
    ?.map((w) => w[0]?.toUpperCase() + w.substring(1).toLowerCase())
    ?.join(" ");
};

export const getAgeFromBirthdate = (dateString: string) => {
  const today = new Date();
  const birthDate = new Date(dateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export const convertUTCToSpecifiedTimezone = (
  utcTimeString: string,
  timezone: string
) => {
  const utcDate = new Date(utcTimeString);
  return dayjs(utcDate).tz(timezone).format("h:mm A");
};

export const convertUTCToLocalTime = (
  utcTimeString: string,
  showTimeZone = true
) => {
  const utcDate = new Date(utcTimeString);

  const options: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  if (showTimeZone) options.timeZoneName = "short";

  const formatter = new Intl.DateTimeFormat("en-US", options);
  return formatter.format(utcDate);
};

export const formatTime = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes.toString().padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
};

export const filterDatesByGivenDayjs = (
  dates: string[],
  targetDate: dayjs.Dayjs,
  timezone: string
): string[] => {
  const targetDateStart = targetDate.format("YYYY-MM-DD");
  return dates?.filter((dateStr) => {
    const dayJsDate = dayjs(dateStr).tz(timezone).format("YYYY-MM-DD");
    return targetDateStart === dayJsDate;
  });
};

export const filterDatesByGivenDate = (
  dates: string[],
  targetDate: Date
): string[] => {
  return dates?.filter((dateStr) => {
    const date = new Date(dateStr);
    // Check if the date matches the target date
    return (
      date.getFullYear() === targetDate.getFullYear() &&
      date.getMonth() === targetDate.getMonth() &&
      date.getDate() === targetDate.getDate()
    );
  });
};

export const removeDuplicateDates = (dates: dayjs.Dayjs[]): dayjs.Dayjs[] => {
  const dateSet = new Set<number>();
  const uniqueDates: dayjs.Dayjs[] = [];

  dates.forEach((date) => {
    const time = date.unix();
    if (!dateSet.has(time)) {
      dateSet.add(time);
      uniqueDates.push(date);
    }
  });

  return uniqueDates;
};

export const includesDate = (
  dates: dayjs.Dayjs[],
  targetDate: dayjs.Dayjs
): boolean => dates.some((date) => date.unix() === targetDate.unix());

export const getReadableTimeZone = (timezone = dayjs.tz.guess()): string => {
  const now = dayjs();

  // Get the offset in hours
  const offset = now.tz(timezone).format("Z");

  // Get the city name from the timezone (splitting the IANA timezone string)
  const cityName = timezone.split("/")[1].replace("_", " ");

  return `${cityName} (UTC${offset})`;
};

export const isPastDate = (date: string): boolean => {
  const todayDate = new Date();
  const targetDate = new Date(date);

  // Set the time to 00:00:00 to compare only the dates
  return targetDate.setHours(0, 0, 0, 0) < todayDate.setHours(0, 0, 0, 0);
};

export const estimateTimeDifference = (date1: Date, date2: Date): string => {
  const msPerMinute = 60 * 1000;
  const msPerHour = msPerMinute * 60;
  const msPerDay = msPerHour * 24;
  const msPerWeek = msPerDay * 7;
  const msPerMonth = msPerDay * 30; // Approximation
  const msPerYear = msPerDay * 365; // Approximation

  const difference = Math.abs(date2.getTime() - date1.getTime());

  if (difference < msPerMinute) {
    return "1m"; // Less than a minute, round up to 1 minute
  } else if (difference < msPerHour) {
    const minutes = Math.round(difference / msPerMinute);
    return `${minutes}m`;
  } else if (difference < msPerDay) {
    const hours = Math.round(difference / msPerHour);
    return `${hours}h`;
  } else if (difference < msPerWeek) {
    const days = Math.round(difference / msPerDay);
    return `${days}d`;
  } else if (difference < msPerMonth) {
    const weeks = Math.round(difference / msPerWeek);
    return `${weeks}w`;
  } else if (difference < msPerYear) {
    const months = Math.round(difference / msPerMonth);
    return `${months}mo`;
  } else {
    const years = Math.round(difference / msPerYear);
    return `${years}y`;
  }
};

export const chunk = <T,>(array: T[], size: number) => {
  if (size <= 0) return [];

  const result = [];

  for (let i = 0; i < array.length; i += size) {
    const chunk = array.slice(i, i + size);

    result.push(chunk);
  }

  return result;
};

export const addWeeksToDate = (weeks: number): Date => {
  const currentDate = new Date();
  const daysToAdd = weeks * 7;
  const newDate = new Date(
    currentDate.setDate(currentDate.getDate() + daysToAdd)
  );
  return newDate;
};
