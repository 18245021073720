import { PushNotifications } from "@capacitor/push-notifications";
import { Capacitor } from "@capacitor/core";
import { createOrUpdateDeviceToken } from "./firestoreService";
import { auth } from "../conf/firebaseConfig";
import {
  AndroidSettings,
  IOSSettings,
  NativeSettings,
} from "capacitor-native-settings";

// Initialize listeners as soon as the app loads
//setupPushListeners();

export async function setupPushListeners() {
  if (!Capacitor.isPluginAvailable("PushNotifications")) {
    return;
  }

  PushNotifications.addListener("registration", (token) => {
    console.log("Push registration success, token:", token.value);
    saveTokenToFirestore(token.value);
  });

  try {
    const permissions = await PushNotifications.checkPermissions();
    if (
      permissions.receive === "prompt" &&
      window.location.pathname !== "/activate-notifications"
    ) {
      // Redirect to the notification activation page if the user hasn't granted permission
      window.location.href = "/activate-notifications";
    }
  } catch (error) {
    console.error("Error checking push notifications:", error);
  }

  PushNotifications.addListener("registrationError", (error) => {
    console.error("Error on push registration:", error);
  });

  PushNotifications.addListener("pushNotificationReceived", (notification) => {
    console.log("Push received:", notification);
    // Handle notification when received while app is in foreground
  });

  PushNotifications.addListener(
    "pushNotificationActionPerformed",
    (notification) => {
      // Handle notification when the user taps on it
      console.log("Push action performed:", notification);
      // Handle deep links or navigate to specific parts of the app
      if (notification.notification.data.link) {
        window.location.href = notification.notification.data.link;
      }
    }
  );
}

export const requestPushPermissionAndRegister = async () => {
  if (!Capacitor.isPluginAvailable("PushNotifications")) {
    return;
  }

  const permission = await PushNotifications.requestPermissions();
  if (permission.receive === "granted") {
    await PushNotifications.register();
  } else {
    console.error("Permission not granted for push notifications");
  }
};

export const saveTokenToFirestore = (token: string) => {
  const userId = auth.currentUser?.uid;
  if (userId) {
    const deviceData = {
      token,
      deviceType: Capacitor.getPlatform(),
      userId,
    };
    console.log("Saving device token to Firestore:", deviceData);
    createOrUpdateDeviceToken(userId, deviceData);
  } else {
    console.error("User ID is undefined");
  }
};

export const openSettings = async () => {
  if (Capacitor.getPlatform() === "android") {
    NativeSettings.openAndroid({
      option: AndroidSettings.ApplicationDetails,
    });
  } else if (Capacitor.getPlatform() === "ios") {
    NativeSettings.openIOS({
      option: IOSSettings.App,
    });
  } else {
    console.log("Unsupported platform");
  }
};
