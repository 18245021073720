import AppointmentScheduler from "./AppointmentScheduler";

const ScheduleAppt = () => {
  return (
    <AppointmentScheduler
      selectDateProps={{
        pageTitle: "SCHEDULE",
        title: "Schedule Your Appointment",
        description:
          "Your clinician is requiring a face-to-face consult. Select a date to schedule an appointment with your clinician for your prescription.",
      }}
      cancelPreviousConsult={false}
    />
  );
};

export default ScheduleAppt;
