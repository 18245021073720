import { useEffect, useState } from "react";
import { useAuth } from "../context/AuthContext";

export const useAuthToken = () => {
  const { currentUser } = useAuth();
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    const getToken = async () => {
      const token = await currentUser?.getIdToken();
      setToken(token ?? null);
    };
    if (currentUser && !token) {
      getToken();
    }
  }, [currentUser, token]);

  return token;
};
