import { get } from "lodash";
import dayjs from "dayjs";

export const calculateDaysSinceCheckin = (legacyUserInfo: IUser) => {
  const lastCheckinDate = get(
    legacyUserInfo,
    "last_checkin.updated_at",
    get(legacyUserInfo, "last_checkin.created_at")
  );

  const checkinAt = dayjs.utc(lastCheckinDate).local().startOf("date");
  const today = dayjs().startOf("date");

  const daysSinceCheckin = today.diff(checkinAt, "days");
  const hasCheckins = !!lastCheckinDate;

  return {
    daysSinceCheckin,
    hasCheckins,
  };
};
