import { useEffect, useState } from "react";
import { UserProfile } from "../typings";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../conf/firebaseConfig";
import { genericConverter } from "../services/firestoreService";

export const useUserRealTime = (userId: string) => {
  const [user, setUser] = useState<Partial<UserProfile> | null>(null);
  useEffect(() => {
    if (!userId) {
      return;
    }

    // Reference to the Firestore document
    const docRef = doc(db, "users", userId).withConverter(
      genericConverter<Partial<UserProfile>>()
    );

    // Set up the real-time listener
    const unsubscribe = onSnapshot(
      docRef,
      (document) => {
        if (document.exists()) {
          setUser({ id: document.id, ...document.data() });
        } else {
          console.log("No such document!");
        }
      },
      (error) => {
        console.error("Error fetching real-time updates: ", error);
      }
    );

    // Clean up the subscription when the component unmounts
    return () => unsubscribe();
  }, [userId]);

  return user;
};
