import { useQuery } from "@tanstack/react-query";
import FuturHealthLegacyAPI from "../../utils/FuturHealthLegacyAPI";
import { useAuth } from "../../context/AuthContext";

export const useGetRecipeFilters = () => {
  const { legacyApiUserToken } = useAuth();

  return useQuery({
    queryKey: ["legacy api", "Get recipe filters"],
    queryFn: () => FuturHealthLegacyAPI.getRecipeFilters(legacyApiUserToken),
    enabled: !!legacyApiUserToken,
  });
};
