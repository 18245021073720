import { Alert, Container } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons-react";

export const ErrorBoundaryFallback = () => {
  const handlePageReload = () => {
    window.location.reload();
  };
  return (
    <Container size="sm" style={{ marginTop: "20px" }}>
      <Alert
        icon={<IconAlertCircle size={16} />}
        title="Error"
        color="red"
        withCloseButton
        onClose={() => handlePageReload()}
      >
        An unexpected error has occurred. Please{" "}
        <button
          type="button"
          onClick={() => handlePageReload()}
          style={{
            cursor: "pointer",
            background: "none",
            border: "none",
            padding: 0,
            textDecoration: "underline",
          }}
          aria-label="Refresh page"
        >
          click this link to refresh the page
        </button>{" "}
        or contact customer support if the issue persists.
      </Alert>
    </Container>
  );
};
