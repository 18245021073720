import React, { useState } from "react";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { Flex, Paper, Stack, Title, Text } from "@mantine/core";
import FHConfirmButton from "./FHConfirmButton";
import { getReadableTimeZone } from "../utils/helpers";
import { useHistory } from "react-router-dom";
import { colors } from "../theme/colors";
import { useStorage } from "../context/StorageContext";
import { useIsMobile } from "../hooks/useIsMobile";

dayjs.extend(utc);
dayjs.extend(timezone);

interface TimezoneListProps {
  currentTimezone: string;
}
const TimezoneList: React.FC<TimezoneListProps> = ({ currentTimezone }) => {
  const { setItem } = useStorage();
  const [confirmIndex, setConfirmIndex] = useState<number>(-1);
  const history = useHistory();
  const isMobile = useIsMobile();

  const timezoneNames = [
    "Pacific/Honolulu",
    "America/Anchorage",
    "America/Juneau",
    "America/Los_Angeles",
    "America/Phoenix",
    "America/Denver",
    "America/Chicago",
    "America/New_York",
  ];

  const handleChange = async (value: string, idx: number) => {
    setConfirmIndex(idx);
    await setItem("defaultTz", value);
    history.goBack();
  };

  return (
    <Paper radius="md" my={"lg"}>
      <Flex justify={isMobile ? "flex-start" : "center"}>
        <Title fz="28px" fw={600} c={colors.black} lh="36px">
          Edit Timezone
        </Title>
      </Flex>
      <Flex justify={isMobile ? "flex-start" : "center"} mt="lg">
        <Text fz="16px" c={colors.nearBlack} lh="24px">
          Update the timezone for your location.
        </Text>
      </Flex>
      <Stack justify="center" align="center" gap="lg" mt="lg">
        {timezoneNames.map((zone, i) => (
          <FHConfirmButton
            key={i}
            index={i}
            confirmIndex={confirmIndex}
            setConfirmIndex={setConfirmIndex}
            selected={zone === currentTimezone}
            onConfirm={() => handleChange(zone, i)}
          >
            {getReadableTimeZone(zone)}
          </FHConfirmButton>
        ))}
      </Stack>
    </Paper>
  );
};

export default TimezoneList;
