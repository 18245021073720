import { useQuery } from "@tanstack/react-query";
import { useAuthToken } from "../useAuthToken";
import {
  getAvailabilitiesForProgram,
  getConsultAvailabilities,
} from "../../services/steadymdService";

export const useConsultAvailabilitiesQuery = (
  consultId: string | undefined
) => {
  const token = useAuthToken();

  return useQuery({
    queryKey: ["steadymd", "availability", consultId],
    queryFn: () => getConsultAvailabilities(consultId ?? "", token ?? ""),
    enabled: !!token && !!consultId,
  });
};

export const useProgramAvailabilitiesQuery = (
  usState: string,
  consultType: string
) => {
  const token = useAuthToken();

  return useQuery({
    queryKey: ["steadymd", "program-availability", { usState, consultType }],
    queryFn: () =>
      getAvailabilitiesForProgram(usState, consultType, token ?? ""),
    enabled: !!token && !!usState && !!consultType,
  });
};
