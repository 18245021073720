import { useEffect, useMemo, useRef } from "react";
import {
  collection,
  getDocs,
  orderBy,
  limit,
  query,
  where,
  doc,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../../conf/firebaseConfig";
import { useAuth } from "../../context/AuthContext";
import { SMDConsult } from "../../typings";
import { useIonViewDidLeave } from "@ionic/react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { ConsultType } from "../../typings/enums";

// Fetch latest consult document
const fetchLatestConsult = async (
  userId: string,
  consultType = [ConsultType.scheduled_video_visit]
): Promise<SMDConsult | null> => {
  const q = query(
    collection(db, "steadymd.consults"),
    where("userId", "==", userId),
    where("consultType", "in", consultType),
    orderBy("createdAt", "desc"),
    limit(1)
  );

  const docQuery = await getDocs(q);
  if (docQuery.empty) {
    return null;
  }

  const doc = docQuery.docs[0];
  return { ...doc.data() } as SMDConsult;
};

export const useLatestConsultRealtime = (
  consultType = [ConsultType.scheduled_video_visit]
) => {
  const queryKey = useMemo(() => {
    return JSON.stringify(["latest consult", { consultType }]);
  }, [consultType]);

  const queryClient = useQueryClient();
  const { currentUser } = useAuth();
  const latestUnsub = useRef<(() => void) | undefined>(undefined);

  // Query for latest consult document
  const query = useQuery({
    queryKey: JSON.parse(queryKey), // Parse back to an object/array
    queryFn: () => fetchLatestConsult(currentUser?.uid as string, consultType),
    enabled: !!currentUser?.uid,
  });

  const consultGuid = query.data?.guid;

  useEffect(() => {
    if (!consultGuid) return;

    // Unsubscribe from any existing subscription
    if (latestUnsub.current) {
      latestUnsub.current();
    }

    // Subscribe to real-time updates on document
    latestUnsub.current = onSnapshot(
      doc(db, "steadymd.consults", consultGuid),
      (docSnapshot) => {
        const newData = docSnapshot.data();
        const currentData = queryClient.getQueryData(JSON.parse(queryKey));

        // Only update cache if data has changed
        if (JSON.stringify(newData) !== JSON.stringify(currentData)) {
          queryClient.setQueryData(JSON.parse(queryKey), newData);
        }
      },
      (error) => {
        console.error("Error fetching steadymd consults: ", error);
      }
    );

    // Clean up subscription on unmount or consultGuid change
    return () => {
      if (latestUnsub.current) {
        latestUnsub.current();
        latestUnsub.current = undefined;
      }
    };
  }, [consultGuid, queryKey, queryClient]);

  useIonViewDidLeave(() => {
    if (latestUnsub.current) {
      latestUnsub.current();
      latestUnsub.current = undefined;
    }
  });

  return query;
};
