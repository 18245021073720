import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

type ZendeskContextType = {
  unreadMessages: number;
};

const ZendeskContext = createContext<ZendeskContextType | undefined>(undefined);

export const useZendesk = (): ZendeskContextType => {
  const context = useContext(ZendeskContext);
  if (!context) {
    throw new Error("useZendesk must be used within a ZendeskProvider");
  }
  return context;
};

interface ZendeskProviderProps {
  children: ReactNode;
}

export const ZendeskProvider = ({ children }: ZendeskProviderProps) => {
  const [unreadMessages, setUnreadMessages] = useState(0);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).zE(
      "messenger:on",
      "unreadMessages",
      function (count: number) {
        setUnreadMessages(count);
      }
    );
  }, []);

  const value: ZendeskContextType = {
    unreadMessages,
  };

  return (
    <ZendeskContext.Provider value={value}>{children}</ZendeskContext.Provider>
  );
};
