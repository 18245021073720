import { useQuery } from "@tanstack/react-query";
import FuturHealthLegacyAPI from "../../utils/FuturHealthLegacyAPI";
import { useAuth } from "../../context/AuthContext";
import { useState } from "react";

export const useGetAllRecipes = () => {
  const { legacyApiUserToken } = useAuth();
  const [shouldFetch, setShouldFetch] = useState(false);

  return {
    ...useQuery({
      queryKey: ["legacy api", "get all recipes"], //For now, we re-use nutrition and meal endpoints from the legacy FH API. Current firebase backend does not implement these features.
      queryFn: async () => {
        try {
          return await FuturHealthLegacyAPI.getAllRecipes(legacyApiUserToken);
        } catch (error) {
          throw new Error("Failed to fetch recipes");
        }
      },
      retry: 3,
      enabled: shouldFetch && !!legacyApiUserToken,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }),
    setShouldFetch,
  };
};
