import localforage from "localforage";
import { useEffect, useState } from "react";

type UseLocalForage = [string | null, (data: string) => void, () => void];

export const useLocalForage = (key: string): UseLocalForage => {
  const [storage, _setStorage] = useState<string | null>(null);

  useEffect(() => {
    (async () => {
      const response = await localforage.getItem<string>(key).catch(() => {});
      if (response) {
        _setStorage(response);
      }
    })();
  }, [key]);

  const setStorage = (data: string) => {
    localforage.setItem<string>(key, data).catch((_) => {});
    _setStorage(data);
  };

  const clearStorage = () => {
    localforage.clear().catch((_) => {});
    _setStorage(null);
  };

  return [storage, setStorage, clearStorage];
};
