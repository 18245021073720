import { useQuery } from "@tanstack/react-query";
import FuturHealthLegacyAPI from "../../utils/FuturHealthLegacyAPI";
import { useAuth } from "../../context/AuthContext";
import { Miscs } from "../../typings/enums";

const getMealPlanSetup = async (token: string) => {
  try {
    const {
      data: { value },
    } = await FuturHealthLegacyAPI.getMisc(token, Miscs.mealPlanSetup);
    return value === "1";
  } catch {
    return false;
  }
};

export const useGetMealPlanSetup = () => {
  const { legacyApiUserToken } = useAuth();
  return useQuery({
    queryKey: ["legacy api", "get meal plan setup"],
    queryFn: async () => await getMealPlanSetup(legacyApiUserToken ?? ""),
    enabled: !!legacyApiUserToken,
  });
};
