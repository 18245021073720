import { initializeApp } from "firebase/app";
import {
  getAuth,
  indexedDBLocalPersistence,
  initializeAuth,
  Auth,
} from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getRemoteConfig } from "firebase/remote-config";
import { Capacitor } from "@capacitor/core";
import { setupPushListeners } from "../services/notificationService";

let firebaseConfig;
if (import.meta.env.MODE === "production") {
  // prod config
  firebaseConfig = {
    apiKey: "AIzaSyAaxXB9qt2b_gF_kNSq2AKsWqbJjKB5VTg",
    authDomain: "futurhealth.firebaseapp.com",
    projectId: "futurhealth",
    storageBucket: "futurhealth.appspot.com",
    messagingSenderId: "126198496791",
    appId: "1:126198496791:web:cd6cec4821ca0e6fdae652",
    measurementId: "G-1Y64QJ48GZ",
  };
} else {
  // staging config
  firebaseConfig = {
    apiKey: "AIzaSyA6EJ9srUWUAHMUE55z3uWyPOmYJoAuIxk",
    authDomain: "futurhealth-staging.firebaseapp.com",
    databaseURL: "https://futurhealth-staging-default-rtdb.firebaseio.com",
    projectId: "futurhealth-staging",
    storageBucket: "futurhealth-staging.appspot.com",
    messagingSenderId: "902116824600",
    appId: "1:902116824600:web:114c0b9394fa7b79f4156d",
    measurementId: "G-ZZ1FDS8RS8",
  };
}

const app = initializeApp(firebaseConfig);

const remoteConfig = getRemoteConfig(app);
remoteConfig.settings.minimumFetchIntervalMillis = 3600000;
remoteConfig.defaultConfig = {};

// Initialize push notification listeners as soon as the app loads
setupPushListeners();

const setupAuth = (): Auth => {
  if (Capacitor.isNativePlatform()) {
    return initializeAuth(app, {
      persistence: indexedDBLocalPersistence,
    });
  }

  return getAuth(app);
};

const auth: Auth = setupAuth();
const db = getFirestore(app);
const analytics = getAnalytics(app);

export { auth, db, analytics, remoteConfig };
