import {
  ChoosingRecipe,
  MealPlanQuestion,
  Params,
  Payload,
  QuizAnswer,
} from "../typings";
import { MealLabels } from "../typings/enums";
import { API_Root } from "./FuturHealthLegacyApiRoot";
const SERVER_PRODUCTION = "https://futurhealth.com/";

export default class FuturHealthLegacyAPI {
  private static domain: string = SERVER_PRODUCTION;

  public static getUserToken(data: Partial<Payload>) {
    return API_Root<IToken, Partial<Payload>>({
      method: "post",
      url: `${FuturHealthLegacyAPI.domain}api/v1/nutrition-plans/firebase-auth-user-token/`,
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
      },
      data,
    });
  }

  public static checkAuth(token: string | null) {
    return API_Root<IAuth, unknown>({
      method: "post",
      url: `${FuturHealthLegacyAPI.domain}api/v1/auth/check/`,
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        ...(token ? { Authorization: `Token ${token}` } : {}),
      },
    });
  }

  public static createMisc(token: string, data: Partial<Payload>) {
    return API_Root({
      method: "post",
      url: `${FuturHealthLegacyAPI.domain}api/v2/misc/`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      data,
    });
  }
  public static getMisc(token: string, key: string) {
    return API_Root<{ value: string }, unknown>({
      method: "get",
      url: `${FuturHealthLegacyAPI.domain}api/v2/misc/${key}/`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    });
  }
  public static putMisc(token: string, data: Partial<Payload>) {
    return API_Root({
      method: "put",
      url: `${FuturHealthLegacyAPI.domain}api/v2/misc/${data.key}/`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      data,
    });
  }

  public static setMisc(token: string, data: Partial<Payload>) {
    return FuturHealthLegacyAPI.putMisc(token, data)
      .then(({ data }) => data)
      .catch(() => {
        return FuturHealthLegacyAPI.createMisc(token, data)
          .then(({ data }) => data)
          .catch((err) => {
            throw err;
          });
      });
  }

  public static createQuiz() {
    return API_Root<{ id: number }, { survey: number }>({
      method: "post",
      url: `${FuturHealthLegacyAPI.domain}api/v1/usersurveys/`,
      data: {
        survey: 134,
      },
    });
  }

  public static getMetabolicType() {
    return API_Root<{ metabolic_type: number }, unknown>({
      method: "get",
      url: `${FuturHealthLegacyAPI.domain}api/v1/usersurveys/134/`,
    });
  }

  public static getMealPlanQuiz() {
    return API_Root<{ questions: MealPlanQuestion[] }, unknown>({
      method: "get",
      url: `${FuturHealthLegacyAPI.domain}api/v1/surveys/134/`,
    });
  }

  static deleteQuizAnswer(quizId: number, surveyId: number) {
    return API_Root({
      method: "delete",
      url: `${FuturHealthLegacyAPI.domain}api/v1/usersurveys/${quizId}/surveyanswers/${surveyId}/`,
    });
  }

  public static postQuizAnswer(
    quizID: number,
    questionId: number,
    answerIds: number[],
    isSingle: boolean
  ) {
    const body: QuizAnswer | QuizAnswer[] = isSingle
      ? {
          question: questionId,
          answer_choice: answerIds[0],
        }
      : answerIds.map((it: number) => ({
          question: questionId,
          answer_choice: it,
        }));

    return API_Root<QuizAnswer | QuizAnswer[], unknown>({
      method: "post",
      url: `${FuturHealthLegacyAPI.domain}api/v1/usersurveys/${quizID}/surveyanswers/`,
      data: body,
    });
  }

  public static getWeekInfo(token: string | null) {
    return API_Root<IWeekInfo, unknown>({
      method: "get",
      url: `${FuturHealthLegacyAPI.domain}api/v1/checkins/week-navigator/`,
      headers: {
        "Content-Type": "application/json",
        ...(token ? { Authorization: `Token ${token}` } : {}),
      },
    });
  }

  static getAllRecipes(token: string | null) {
    return API_Root<IRecipeInLibrary[], undefined>({
      method: "get",
      url: `${FuturHealthLegacyAPI.domain}api/v2/recipes/?restrictions=1`,
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        ...(token ? { Authorization: `Token ${token}` } : {}),
      },
    });
  }

  static getRecipeById({
    recipeId,
    token,
  }: {
    recipeId: string;
    token: string | null;
  }) {
    return API_Root<IRecipeInLibrary, undefined>({
      method: "get",
      url: `${FuturHealthLegacyAPI.domain}api/v2/recipes/${recipeId}/`,
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        ...(token ? { Authorization: `Token ${token}` } : {}),
      },
    });
  }

  static getRecipeFilters(token: string | null) {
    return API_Root<IRecipeFilter[], undefined>({
      method: "get",
      url: `${FuturHealthLegacyAPI.domain}api/v2/tags-categories/`,
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        ...(token ? { Authorization: `Token ${token}` } : {}),
      },
    });
  }

  public static getMealPlan({
    data,
    token,
  }: {
    token: string;
    data?: {
      weekNumber?: number;
      dayNumber?: number;
      checkin?: number;
    };
  }) {
    return API_Root<IMealPlan, IGetMealPlansParams>({
      method: "get",
      url: `${FuturHealthLegacyAPI.domain}api/v2/new-mymealplan/`,
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        Authorization: `Token ${token}`,
      },
      params: {
        week_number: data?.weekNumber,
        day_number: data?.dayNumber,
        checkin: data?.checkin,
      },
    });
  }

  /**
   *
   * @param {string!} token - Authorization token
   * @param {1|2|3|4|5|6|7} dayNumber - Day number
   * @param {'current'|'next'} weekString - Week string to which day belongs
   */
  public static getDaySwappableRecipes(
    token: string,
    dayNumber: number,
    weekString: string
  ) {
    return API_Root<
      {
        recipes: IRecipe[];
        swaps_for_meals: { [key in number]: number[] };
      },
      Params
    >({
      method: "get",
      url: `${FuturHealthLegacyAPI.domain}api/v2/recipes/swappable-for-meals/`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      params: {
        week: weekString,
        day_number: dayNumber,
      },
    });
  }

  public static getSwappableRecipesByLabel(token: string, weekNumber: number) {
    return API_Root<{ [key in MealLabels]: IRecipe[] }, unknown>({
      method: "get",
      url: `${FuturHealthLegacyAPI.domain}api/v1/swappable-recipes-by-label/`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      params: {
        week_number: weekNumber,
      },
    });
  }

  public static getAllCheckins(token: string) {
    return API_Root<ICheckin[], unknown>({
      method: "get",
      url: `${FuturHealthLegacyAPI.domain}api/v1/checkins/`,
      headers: {
        Authorization: `Token ${token}`,
      },
    });
  }

  public static createCheckin(token: string, data: Partial<ICheckin>) {
    return API_Root({
      method: "post",
      url: `${FuturHealthLegacyAPI.domain}api/v1/checkins/`,
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        Authorization: `Token ${token}`,
      },
      data,
    });
  }

  public static getMeal({
    mealId,
    token,
  }: {
    mealId: string;
    token: string | null;
  }) {
    return API_Root<IMeal, unknown>({
      method: "get",
      url: `${FuturHealthLegacyAPI.domain}api/v2/usermeals/${mealId}/`,
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        ...(token ? { Authorization: `Token ${token}` } : {}),
      },
    });
  }

  public static storeCheckin(
    token: string,
    metabolicType: number,
    weight: string,
    weekNumber: number
  ) {
    const data: Partial<Payload> = {
      metabolic_type: metabolicType,
      week_number: weekNumber,
      weight: weight,
    };
    return API_Root({
      method: "post",
      url: `${FuturHealthLegacyAPI.domain}api/v1/checkins/`,
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      data,
    });
  }

  static updateAccountInfo(token: string, userInfo: IUser) {
    const userName = userInfo.username ?? userInfo.first_name;
    if (!userName) {
      throw new Error("Username or first name must be provided");
    }
    return API_Root<IUser, IUser>({
      method: "put",
      url: `${FuturHealthLegacyAPI.domain}api/v1/accounts/${userName}/`,
      headers: {
        Authorization: `Token ${token}`,
      },
      data: userInfo,
    });
  }

  // this API is used in onboarding and after checkin to replace upcoming week's meal plan with swappable recipes
  static swapToRecipes(
    token: string,
    recipesAndLabels: ChoosingRecipe[],
    weekNumber: number
  ) {
    // If error.response.status === 409, it means this request has been tried when the meal plan generation was in progress
    return API_Root<IRecipe, Partial<Payload>>({
      method: "post",
      url: `${FuturHealthLegacyAPI.domain}api/v2/usermeals/swap-to-recipes/`,
      headers: {
        Authorization: `Token ${token}`,
      },
      params: {
        week_number: weekNumber,
      },
      data: {
        recipes_and_labels: recipesAndLabels,
      },
    });
  }

  static logMeal(token: string | null, mealId: number) {
    return API_Root({
      method: "post",
      url: `${this.domain}api/v2/loggedmeals/`,
      headers: {
        ...(token ? { Authorization: `Token ${token}` } : {}),
      },
      data: {
        meal: mealId,
      },
    });
  }

  static unlogMeal(token: string | null, mealId: number, loggedId?: number) {
    return API_Root({
      method: "delete",
      url: `${this.domain}api/v2/loggedmeals/${loggedId}/?logged_meal__meal_id=${mealId}`,
      headers: {
        Authorization: `Token ${token}`,
      },
    });
  }

  public static swapMeal({
    mealId,
    token,
    meal,
  }: {
    mealId: number;
    token?: string;
    meal: IMeal<IRecipeInLibrary>;
  }) {
    if (!token) {
      throw new Error("User is not authenticated");
    }
    return API_Root<IMeal, unknown>({
      method: "put",
      url: `${FuturHealthLegacyAPI.domain}api/v2/usermeals/${mealId}/`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        ...(token ? { Authorization: `Token ${token}` } : {}),
      },
      data: meal,
    });
  }

  static getRecipesFromTags({
    token,
    tags,
    mealId,
    weekString,
  }: {
    token: string | null;
    tags?: IRecipeFilterTag[];
    mealId?: string | null;
    weekString: string;
  }) {
    if (!token) {
      throw new Error("User is not authenticated");
    }

    const tagsQuery =
      tags && tags.length
        ? tags.reduce((result, tag, index) => {
            return `${result}tags__slug=${tag}${index === tags.length - 1 ? "" : "&"}`;
          }, "?")
        : "";

    const url = `${FuturHealthLegacyAPI.domain}api/v2/all-recipes/${tagsQuery}${
      mealId ? `${tagsQuery === "" ? "?" : "&"}user_meal=${mealId}` : ""
    }${weekString === "next" ? "&swappable=next_week" : ""}`;

    return API_Root<IRecipeInLibrary[], unknown>({
      method: "get",
      url,
      headers: { Authorization: `Token ${token}` },
    });
  }

  static getRecipeTagsCategories(token: string | null) {
    if (!token) {
      throw new Error("User is not authenticated");
    }

    return API_Root<IRecipeTagsCategory[], unknown>({
      method: "get",
      url: `${FuturHealthLegacyAPI.domain}api/v2/tags-categories/`,
      headers: { Authorization: `Token ${token}` },
    });
  }
}
